import { EconomyReportPayload, TariffModality, UnitFormData } from '@contexts/economy-reports/types';

type defaultInputsTypes = {
  field: keyof EconomyReportPayload;
  label: string;
  placeholder: string;
  prefix?: string;
  suffix?: string;
  allowNegative: boolean;
  required: boolean;
  style?: string;
  decimalScale: 2 | 3;
  showInFixedPriceModality?: boolean;
  showRetailServiceModality?: boolean;
  defaultInput?: boolean;
  displayInBlueTariffModality?: boolean;
  tooltipContent?: string;
};

export const acrInputs: defaultInputsTypes[] = [
  {
    field: 'acrTariffFlagCost',
    label: 'Valor bandeira tarifária ',
    placeholder: 'R$/MWh',
    prefix: 'R$',
    suffix: '/MWh',
    allowNegative: false,
    decimalScale: 2,
    required: true,
    style: 'xl:mt-6 1xl:mt-0',
  },
  {
    field: 'acrOthersCosts',
    label: 'Outras cobranças Cativo',
    placeholder: 'R$',
    prefix: 'R$',
    allowNegative: true,
    decimalScale: 2,
    required: true,
    style: 'xl:mt-6 1xl:mt-0',
    tooltipContent:
      'Valores relacionados a cobranças do mercado cativo, como multas, atrasos, iluminação pública, reembolsos, pagamentos à distribuidora (DIC/FIC/DMIC), pagamentos duplicados, desligamentos, religamentos e outros.',
  },
];

export const providedAclInputs: defaultInputsTypes[] = [
  {
    field: 'aclEnergyProvided',
    label: 'Energia fornecida',
    placeholder: 'MWh',
    suffix: ' MWh',
    allowNegative: true,
    decimalScale: 3,
    required: true,
  },
  {
    field: 'aclEnergyProvidedCost',
    label: 'Preço da energia fornecida',
    placeholder: 'R$/MWh',
    prefix: 'R$',
    suffix: '/MWh',
    decimalScale: 2,
    required: true,
    allowNegative: true,
    showInFixedPriceModality: true,
    style: 'xl:-mt-6 1xl:mt-0',
  },
  {
    field: 'clarkeManagementCost',
    label: 'Gestão Clarke',
    placeholder: 'R$',
    prefix: 'R$',
    allowNegative: false,
    decimalScale: 2,
    required: true,
  },
  {
    field: 'aclEerCost',
    label: 'EER',
    placeholder: 'R$',
    prefix: 'R$',
    decimalScale: 2,
    required: true,
    allowNegative: true,
    showRetailServiceModality: false,
  },
  {
    field: 'aclErcapCost',
    label: 'Ercap',
    placeholder: 'R$',
    prefix: 'R$',
    decimalScale: 2,
    required: true,
    allowNegative: false,
    showRetailServiceModality: false,
  },
  {
    field: 'aclEssCost',
    label: 'Liquidação + ESS',
    placeholder: 'R$',
    prefix: 'R$',
    decimalScale: 2,
    required: true,
    allowNegative: true,
    showRetailServiceModality: false,
  },
  {
    field: 'aclCceeCost',
    label: 'Contribuição CCEE',
    placeholder: 'R$',
    prefix: 'R$',
    decimalScale: 2,
    required: true,
    allowNegative: true,
    showRetailServiceModality: false,
  },
  {
    field: 'aclEnergyProvidedAdjustmentValue',
    label: 'Devoluções/Ajustes',
    placeholder: 'R$',
    prefix: 'R$',
    decimalScale: 2,
    required: false,
    allowNegative: true,
  },
];

export const distributionAclInputs: defaultInputsTypes[] = [
  {
    field: 'aclReadDemandPeak',
    label: 'Demanda lida P',
    placeholder: 'kW',
    suffix: ' kW',
    allowNegative: true,
    decimalScale: 3,
    required: true,
    style: 'none',
    displayInBlueTariffModality: true,
  },
  {
    field: 'aclReadDemandOffPeak',
    label: 'Demanda lida FP',
    placeholder: 'kW',
    suffix: ' kW',
    allowNegative: true,
    decimalScale: 3,
    required: true,
  },
  {
    field: 'aclEnergyConsumptionPeak',
    label: 'Consumo P',
    placeholder: 'kWh',
    suffix: ' kWh',
    allowNegative: true,
    decimalScale: 3,
    required: true,
  },
  {
    field: 'aclEnergyConsumptionOffPeak',
    label: 'Consumo FP',
    placeholder: 'kWh',
    suffix: ' kWh',
    allowNegative: true,
    decimalScale: 3,
    required: true,
  },
  {
    field: 'aclReactiveDemandPeak',
    label: 'Demanda reativa P',
    placeholder: 'kVAh',
    suffix: ' kVAh',
    allowNegative: true,
    decimalScale: 3,
    required: true,
    displayInBlueTariffModality: true,
  },
  {
    field: 'aclReactiveDemandOffPeak',
    label: 'Demanda reativa FP',
    placeholder: 'kVAh',
    suffix: ' kVAh',
    allowNegative: true,
    decimalScale: 3,
    required: true,
  },
  {
    field: 'aclReactiveEnergyPeak',
    label: 'Energia reativa P',
    placeholder: 'kVARh',
    suffix: '  kVARh',
    allowNegative: true,
    decimalScale: 3,
    required: true,
  },
  {
    field: 'aclReactiveEnergyOffPeak',
    label: 'Energia reativa FP',
    placeholder: 'kVARh',
    suffix: ' kVARh',
    allowNegative: true,
    decimalScale: 3,
    required: true,
  },
  {
    field: 'aclReactiveEnergyCost',
    label: 'Tarifa energia reativa',
    placeholder: 'R$/MWh',
    prefix: 'R$',
    suffix: '/MWh',
    allowNegative: true,
    decimalScale: 2,
    required: true,
  },
  {
    field: 'aclTusdDiscount',
    label: 'Desconto na TUSD',
    placeholder: '%',
    suffix: ' %',
    allowNegative: false,
    decimalScale: 2,
    required: true,
    displayInBlueTariffModality: false,
  },
  {
    field: 'aclCovidCost',
    label: 'Encargo COVID-19 + Escassez Hídrica',
    placeholder: 'R$',
    prefix: 'R$',
    allowNegative: true,
    decimalScale: 2,
    required: false,
  },
  {
    field: 'aclOthersCosts',
    label: 'Outras cobranças Livre',
    placeholder: 'R$',
    prefix: 'R$',
    allowNegative: true,
    decimalScale: 2,
    required: true,
  },
  {
    field: 'aclDelays',
    label: 'Atrasos',
    placeholder: 'R$',
    prefix: 'R$',
    allowNegative: true,
    decimalScale: 2,
    required: false,
  },

  {
    field: 'aclPisCofins',
    label: 'PIS/COFINS',
    placeholder: '%',
    suffix: '%',
    allowNegative: true,
    decimalScale: 2,
    required: true,
  },
  {
    field: 'aclDistributionAdjustmentValue',
    label: 'Ajustes de cálculo',
    placeholder: 'R$',
    prefix: 'R$',
    allowNegative: true,
    decimalScale: 2,
    required: false,
  },
  {
    field: 'aclLoadFactor',
    label: 'Fator de carga',
    placeholder: '%',
    suffix: ' %',
    allowNegative: false,
    decimalScale: 2,
    required: false,
  },
];

export const filterUnit = (units: UnitFormData[], unitId: string): UnitFormData | null =>
  units.filter(({ id }) => id === unitId)[0];

export const inputsFilteredByTariffModality = (tariffModality: TariffModality) => {
  if (tariffModality === TariffModality.A4_GREEN) {
    return distributionAclInputs.filter(
      ({ displayInBlueTariffModality, field }) => !displayInBlueTariffModality && field !== 'aclLoadFactor',
    );
  }

  if (tariffModality !== TariffModality.A3_BLUE) {
    return distributionAclInputs.filter(({ field }) => field !== 'aclLoadFactor');
  }

  return distributionAclInputs;
};

export const inputsFilteredByRetailServiceAndContract = (
  contractType: 'GUARANTEED_SAVING' | 'FIXED_PRICE',
  retailService: boolean,
  chargesForRetailService: boolean,
) => {
  const whosaleGuarantedSavingType = !retailService && contractType === 'GUARANTEED_SAVING';
  const retailGuarantedSavingDontShowCharges =
    !chargesForRetailService && retailService && contractType === 'GUARANTEED_SAVING';
  const retailGuarantedSavingShowCharges =
    chargesForRetailService && retailService && contractType === 'GUARANTEED_SAVING';

  const retailFixedPriceDontShowCharges = !chargesForRetailService && retailService && contractType === 'FIXED_PRICE';

  if (whosaleGuarantedSavingType) {
    return providedAclInputs.filter(({ showInFixedPriceModality }) => !showInFixedPriceModality);
  }

  if (retailGuarantedSavingDontShowCharges) {
    return providedAclInputs.filter(
      ({ showInFixedPriceModality, showRetailServiceModality }) =>
        !showInFixedPriceModality && showRetailServiceModality !== false,
    );
  }

  if (retailGuarantedSavingShowCharges) {
    return providedAclInputs.filter(({ showInFixedPriceModality }) => !showInFixedPriceModality);
  }

  if (retailFixedPriceDontShowCharges) {
    return providedAclInputs.filter(({ showRetailServiceModality }) => showRetailServiceModality !== false);
  }
  return providedAclInputs;
};
